<template>
    <div class="order_buy">
        <van-nav-bar title="购物车" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="store">
            <van-cell :title="lbsStoreTitle" title-width="60%" icon="shop-o" is-link @click="toStoreIndex" value="更换网点"
                class="store_box" value-class="store_box_value" />
        </div>
        <div class="product">
            <van-card :tag="productInfo.category_label" :price="productInfo.money / 100" :desc="productInfo.intro"
                :title="productInfo.title" :thumb="productInfo.cover" :num="productNum" price-class="product_price"
                custom-class="product_box">
            </van-card>
        </div>
        <div class="order">
            <van-row gutter="20" class="buy_row">
                <van-col span="12">
                    <div class="col_label">
                        <van-icon name="cart-o" /> 购买数量
                    </div>
                </van-col>
                <van-col span="12">
                    <van-stepper v-model="productNum" async-change @change="numConfirm" class="buy_num" />
                </van-col>
            </van-row>


            <van-cell center title="配送时间" icon="clock-o" :value="sendDate" is-link @click="showSendDatePopup" />
            <van-popup overlay v-model:show="isSendDateShow" @close="onCloseSendDatePopup" position="bottom">
                <van-date-picker type="date" title="配送时间" v-model:value="currentDate" :formatter="formatter"
                    :min-date="minDate" :max-date="maxDate" @confirm="onSendDateConfirm" @cancel="onCloseSendDatePopup" />
            </van-popup>
            <van-cell center title="支付方式" icon="balance-o" :value="payTypeValue" is-link @click="showPayTypePopup" />
            <van-popup overlay v-model:show="isPayTypeShow" @close="onClosePayTypePopup" position="bottom">
                <van-picker :columns="payTypeData" title="支付方式" @cancel="onClosePayTypePopup"
                    @confirm="onConfirmPayTypePopup" />
            </van-popup>
            <van-notice-bar mode="closeable" text="支持微信支付、会员卡支付，可切换支付方式" />
        </div>
        <van-divider contentPosition="center"
            customStyle="color: #969799; border-color: #ededed; font-size: 14px;margin-top:50rpx;">
            取货信息
        </van-divider>
        <div class="buyer">
            <van-cell-group>
                <van-field v-model="userRealName" label="姓名" placeholder="请输入您的姓名" left-icon="contact" />
                <van-field v-model="userPhone" label="手机号码" placeholder="请输入手机号码" left-icon="phone-o" />
            </van-cell-group>
        </div>
        <div v-if="userCoupon">
            <van-divider contentPosition="center"
                customStyle="color: #969799; border-color: #ededed; font-size: 14px;margin-top:50rpx;">
                优惠信息
            </van-divider>
            <van-cell title="优惠券" icon="coupon-o" :value="userCoupon.coupon_title"
                :label="`${'满 ' + (userCoupon.coupon_money / 100) + ' 元可用'}`" />
            <van-cell title="优惠减免" icon="refund-o" :value="`${'- ' + userCoupon.rate_money / 100 + ' 元'}`" />
        </div>
        <van-divider contentPosition="center"
            customStyle="color: #969799; border-color: #ededed; font-size: 14px;margin-top:50rpx;">
            购买须知
        </van-divider>
        <div class="order_info order_content">
            <div v-html="orderInfo"></div>
        </div>
        <van-action-bar v-if="showClose === false">
            <van-action-bar-icon icon="chat-o" text="客服" @click="onClickIcon" />
            <van-action-bar-button type="danger" :text="`${'立即支付(' + payMoney + '元)'}`" @click="toPaySubmitDialog" />
        </van-action-bar>
    </div>
    <div class="product_dialog">
        <van-dialog use-slot title="温馨提示" v-model:show="showProductDialog" confirmButtonText="确认支付" cancelButtonText="取消支付"
            show-cancel-button @confirm="toPaySubmit" @close="onProductPayClose" zIndex="999">
            <div class="dialog_box">
                <div class="box_title">
                    <van-icon name="shop" /> 取货网点： {{ lbsStoreTitle }}
                </div>
                <div class="box_title">
                    <van-icon name="clock" /> 取货日期： {{ sendDate }}
                </div>
                <div class="box_title">
                    <van-icon name="send-gift" /> 配送数量： {{ productNum }} {{ productInfo.unit }}
                </div>
            </div>
        </van-dialog>
        <van-dialog use-slot title="温馨提示" v-model:show="showCycleDialog" confirmButtonText="确认支付" cancelButtonText="取消支付"
            show-cancel-button @confirm="toPaySubmit" @close="onCyclePayClose" zIndex="999">
            <div class="dialog_box">
                <div class="box_title">
                    <van-icon name="shop" /> 取货网点： {{ lbsStoreTitle }}
                </div>
                <div class="box_title">
                    <van-icon name="clock" /> 取货日期： {{ sendDate }}
                </div>
                <div class="box_title">
                    <van-icon name="send-gift" /> 配送数量： {{ productNum * productInfo.product_num }} 袋/天
                </div>
            </div>
        </van-dialog>
        <van-dialog use-slot title="温馨提示" v-model:show="showClose" confirmButtonText="我知道了" zIndex="999">
            <div class="dialog_box">
                <div class="box_msg">
                    {{ closeMessage }}
                </div>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import { onBeforeMount, onMounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import request from '../../utils/request';
import { showConfirmDialog, showDialog, showNotify } from 'vant';
export default {
    setup() {
        const lbsStoreTitle = ref('');
        const orderStoreId = ref(0);
        const route = useRoute();
        const router = useRouter();
        const toStoreIndex = () => {
            router.push({ name: 'Store' })
        };
        const productId = ref(0);
        const productInfo = ref('');
        const productNum = ref(1);
        onBeforeMount(() => {
            if (route.query.productid) {
                productId.value = route.query.productid
            }
            currentDate.value = new Date().getTime() + 24 * 60 * 60 * 1000
            minDate.value = new Date().getTime() + 24 * 60 * 60 * 1000
            maxDate.value = new Date().getTime() + 24 * 60 * 60 * 1000

            loadProductInfo()

            loadUserLastOrderStoreInfo()
        });
        onMounted(() => {
            var orderStore = localStorage.getItem('orderStore')
            if (orderStore) {
                orderStore = JSON.parse(orderStore)
                orderStoreId.value = orderStore.id
                lbsStoreTitle.value = orderStore.title
            }

            if(!lbsStoreTitle.value){
                // 提示选择网点
                showDialog({
                    title: '温馨提示',
                    message: '您尚未选择取奶网点，请先选择您附近的网点'
                }).then(() => {
                    router.push({ name: 'Store' })
                })
            }
        });
        const loadUserLastOrderStoreInfo = () => {
            request({
                url: '/app/user/last_order_store',
                method: 'GET'
            }).then((res) => {
                if (res) {
                    var lastInfo = res.last_order_store
                    if (lastInfo.store_d) {
                        orderStoreId.value = lastInfo.store_d
                        lbsStoreTitle.value = lastInfo.store_name

                        var selectedStoreInfo = {
                            id: lastInfo.store_d,
                            title: lastInfo.store_name
                        }
                        localStorage.setItem('orderStore', JSON.stringify(selectedStoreInfo))
                    }
                }
            });
        };
        const loadProductInfo = () => {
            request({
                url: '/app/product_cart/' + productId.value,
                method: 'GET'
            }).then((res) => {
                if (res) {
                    if (res.close_status == 1) {
                        showClose.value = true
                        closeMessage.value = res.close_message
                    }
                    var payMoneyData = res.product.money
                    var couponInfo = res.coupon
                    if (couponInfo) {
                        // 判断金额
                        if (couponInfo.coupon_money <= res.product.money) {
                            // 支付金额重置
                            payMoneyData -= couponInfo.rate_money
                        }
                    }
                    productInfo.value = res.product
                    payMoney.value = payMoneyData / 100
                    orderInfo.value = res.order
                    sendDate.value = res.sendAt ? res.sendAt : timeFormat(new Date((new Date).getTime() + 24 * 60 * 60 * 1000))
                    currentDate.value = new Date(res.sendDay.currentDate * 1000)
                    minDate.value = new Date(res.sendDay.minDate * 1000)
                    maxDate.value = new Date(res.sendDay.maxDate * 1000)
                    userCoupon.value = couponInfo
                }
            });
        };
        const numConfirm = (num) => {
            if (!num) {
                productNum.value = 1
            }
            if (num > 3) {
                var modalMsg = '您选购的商品非月订商品，确定需要购买这么多数量吗？';
                if (productInfo.value.category_id == 3) {
                    // 月订
                    modalMsg = '您选购的商品为月订商品，每日配送数量：' + productInfo.value.product_num * num + ' 袋，确定需要购买这么多数量吗？'
                }
                showConfirmDialog({
                    title: '温馨提示',
                    message: modalMsg
                }).then(() => {
                    payMoney.value = setPayMoney(productInfo.value.money * num)
                }).catch(() => {
                    payMoney.value = setPayMoney(productInfo.value.money * (num - 1))
                    productNum.value = num - 1
                })
            } else {
                payMoney.value = setPayMoney(productInfo.value.money * num)
            }
        };
        const setPayMoney = (money) => {
            // 读取优惠金额
            var couponInfo = userCoupon.value;
            if (couponInfo && money >= couponInfo.coupon_money) {
                return (money - couponInfo.rate_money) / 100;
            }
            return money / 100;
        };
        const sendDate = ref('');
        const showSendDatePopup = () => {
            isSendDateShow.value = true
        };
        const isSendDateShow = ref(false);
        const onCloseSendDatePopup = () => {
            isSendDateShow.value = false
        };
        const currentDate = ref('');
        const minDate = ref('');
        const maxDate = ref('');
        const formatter = (type, option) => {
            if (type === 'year') {
                option.text += '年';
            }
            if (type === 'month') {
                option.text += '月';
            }
            return option;
        };
        const onSendDateConfirm = ({ selectedValues, selectedOptions }) => {
            isSendDateShow.value = false
            sendDate.value = selectedValues[0] + '-' + selectedValues[1] + '-' + selectedValues[2]
        };
        const timeFormat = (time) => { // 时间格式化 2019-09-08
            let year = time.getFullYear();
            let month = time.getMonth() + 1;
            let day = time.getDate();
            return year + '年' + month + '月' + day + '日'
        };
        const payTypeData = reactive([
            { text: '微信支付', value: '1' },
            { text: '余额支付', value: '2' },
        ]);
        const payTypeValue = ref('微信支付');
        const payTypeIndex = ref('1');
        const isPayTypeShow = ref(false);
        const showPayTypePopup = () => {
            isPayTypeShow.value = true
        };
        const onClosePayTypePopup = () => {
            isPayTypeShow.value = false
        };
        const onConfirmPayTypePopup = ({ selectedOptions }) => {
            isPayTypeShow.value = false;
            payTypeValue.value = selectedOptions[0].text;
            payTypeIndex.value = selectedOptions[0].value;
        };
        const userRealName = ref('');
        const userPhone = ref('');
        const userCoupon = ref('');
        const orderInfo = ref('');
        const showClose = ref(false);
        const payMoney = ref(0);
        const onClickIcon = () => {
            router.push({ name: 'Contact' })
        };
        const toPaySubmitDialog = () => {
            if (!userRealName.value || !userPhone.value) {
                showNotify({
                    message: '请填写取货信息中的姓名和手机号码',
                })
                return false
            }
            if (productInfo.value.category_id == 3) {
                showCycleDialog.value = true
            } else {
                showProductDialog.value = true
            }
        };
        const showCycleDialog = ref(false);
        const showProductDialog = ref(false);
        const toPaySubmit = () => {
            if (!orderStoreId.value) {
                showDialog({
                    title: '温馨提示',
                    message: '您尚未选择取奶网点，请先选择您附近的网点'
                }).then(() => {
                    router.push({ name: 'Store' })
                })
                return false;
            }
            // 判断优惠券
            var couponId = 0;
            var couponInfo = userCoupon.value;
            if (couponInfo && (couponInfo.coupon_money / 100) <= payMoney.value) {
                couponId = couponInfo.id;
            }
            let payType = payTypeIndex.value
            request({
                url: '/app/user/order_pay',
                method: 'POST',
                data: {
                    data: {
                        product_id: productId.value,
                        product_num: productNum.value,
                        store_id: orderStoreId.value,
                        pay_type: payType,
                        sended_at: sendDate.value,
                        user_real_name: userRealName.value,
                        user_mobile: userPhone.value,
                        coupon_id: couponId,
                    }
                }
            }, true).then((res) => {
                if (res.status && payType == 2) {
                    //余额支付成功 跳转
                    router.push({ name: 'Order' })
                }
                if (res.status && payType == 1) {
                    payParam.value = res.pay_param;
                    if (payMoney.value == 0) {
                        router.push({ name: 'Order' })
                    } else {
                        if (typeof WeixinJSBridge == "undefined") {
                            if (document.addEventListener) {
                                document.addEventListener(
                                    "WeixinJSBridgeReady",
                                    onBridgeReady,
                                    false
                                );
                            } else if (document.attachEvent) {
                                document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                                document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
                            }
                        } else {
                            onBridgeReady();
                        }
                    }
                }
            })
        };
        const payParam = ref('');
        const onBridgeReady = () => {
            WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                {
                    appId: payParam.value.appId, //公众号ID，由商户传入
                    timeStamp: payParam.value.timeStamp, //时间戳，自1970年以来的秒数
                    nonceStr: payParam.value.nonceStr, //随机串
                    package: payParam.value.package,
                    signType: payParam.value.signType, //微信签名方式：
                    paySign: payParam.value.paySign,
                },
                (res) => {
                    console.log(res);
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        router.push({ name: "Order" });
                    } else {
                        showNotify({
                            message: '支付失败，请稍后再试',
                        })
                    }
                }
            );
        };
        const onProductPayClose = () => {
            showProductDialog.value = false
        };
        const onCyclePayClose = () => {
            showCycleDialog.value = false
        };
        const closeMessage = ref('');
        const onClickLeft = () => history.back();
        return {
            lbsStoreTitle,
            toStoreIndex,
            productInfo,
            productNum,
            numConfirm,
            sendDate,
            showSendDatePopup,
            isSendDateShow,
            onCloseSendDatePopup,
            currentDate,
            minDate,
            maxDate,
            formatter,
            onSendDateConfirm,
            payTypeData,
            payTypeValue,
            isPayTypeShow,
            payTypeIndex,
            showPayTypePopup,
            onClosePayTypePopup,
            onConfirmPayTypePopup,
            userRealName,
            userPhone,
            userCoupon,
            orderInfo,
            showClose,
            payMoney,
            onClickIcon,
            toPaySubmitDialog,
            showCycleDialog,
            toPaySubmit,
            onProductPayClose,
            onCyclePayClose,
            closeMessage,
            showProductDialog,
            onClickLeft,

        }
    },
}
</script>
<style>
.order_buy {
    overflow: hidden;
}

.order {
    width: 100%;
}

.order .num_input {
    color: #07C160;
    font-size: 18px;
}

.order .num_right_icon {
    color: #07C160;
}


.buy_row {
    padding: 10px 16px;
    border-bottom: 1px solid #fafafa;
    font-size: 14px;
}

.buy_row .col_label {
    padding: 5px 0;
    vertical-align: middle;
}

.buy_row .buy_num {
    float: right;
}

.order_buy .store {
    background-color: #07C160;
    color: #ffffff;
}

.order_buy .store .store_box {
    background-color: #07C160;
    color: #ffffff;
    font-weight: bolder;
}

.order_buy .store .store_box_value {
    color: #ffffff;
}

.order_buy .store .van-cell__right-icon {
    color: #ffffff;
}

.product_dialog {}

.product_dialog .dialog_box {
    padding: 10px;
}

.product_dialog .dialog_box .box_title {
    font-size: 16px;
    color: #ee0a24;
    font-weight: bolder;
    margin-bottom: 10px;
}

.product_dialog .dialog_box .box_address {
    font-size: 12px;
    color: #646566;
    margin-bottom: 10px;
}

.product_dialog .dialog_box .box_intro {
    font-size: 12px;
    color: #646566;
    margin-bottom: 10px;
    text-indent: 2em;
    line-height: 25px;
}

.product_dialog .dialog_box .box_msg {
    font-size: 14px;
    color: #646566;
    margin-bottom: 10px;
    text-indent: 2em;
    line-height: 25px;
}

.order_content {
    padding: 10px;
    font-size: 14px;
    color: #666666;
}
</style>